<template>
  <div class="v bc-l">
    <div class="flex scroll-able">
      <el-tree ref="tree" class="highlight-tree" node-key="_id" :data="data" highlight-current :props="props" :load="loadNode" lazy @node-click="handleNodeClick" />
    </div>
  </div>
</template>

<script>
// import initData from "@/mixins/initData";
import request from "@/utils/request";
export default {
  // mixins: [initData],
  props: {
    value: Number | String
  },
  data() {
    return {
      data: [],
      props: {
        label: "name",
        isLeaf: function(data) {
          return data.level >= 3;
        }
      }
    };
  },
  mounted() {},
  methods: {
    //懒加载时触发
    loadNode(node, resolve) {
      if (node.level === 0) {
        let res = [{ name: "全国", code: "", level: "0" }];
        this.buildNodeKey(res);
        let autoSelect = res[0];
        setTimeout(_ => {
          this.$refs.tree && this.$refs.tree.setCurrentKey(autoSelect._id);
          this.handleNodeClick(autoSelect);
        }, 200);
        return resolve(res);
      }
      if (node && node.level === 1) {
        request({
          url: "api/regionalDict/province?page=0&size=10000",
          method: "get"
        }).then(res => {
          return resolve(res || []);
        });
      }
      if (node.data.code && node.level > 1) {
        request({
          url:
            "api/regionalDict/parent/" +
            node.data.code +
            "?" +
            "page=0&size=10000",
          method: "get"
        }).then(res => {
          return resolve(res || []);
        });
      }
    },
    buildNodeKey(source) {
      (source || []).forEach(o => {
        o._id = this.$uuid();
      });
    },
    handleNodeClick(data) {
      if (data) {
        this.$emit("input", data.code);
        this.$emit("update:type", data.level);
        this.$emit("change", data);
      }
    }
  }
};
</script>
